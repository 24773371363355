import { useContext, useEffect, useRef, useState } from "react";
import DataContext from "../../Component/Element/context";
import OrderSummary from "./compoment/order_summary";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Component/Service/apiservices";
import sessionCartData from "../../Component/Element/cart_session_data";
import { toast } from "react-toastify";
import Loader from "react-js-loader";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { BrowserView, MobileView } from "react-device-detect";
const Checkout = () => {
    const Navigate = useNavigate();
    const { Razorpay } = useRazorpay();
    const sessionData = sessionCartData();
    const contextValues = useContext(DataContext);
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [continuePaySpinnerLoading, setContinuePaySpinnerLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("COD");
    const [shippingCharge, setShippingCharge] = useState(0);
    const [textarea, setTextarea] = useState('');
    const didMountRef = useRef(true)

    useEffect(() => {
        if (didMountRef.current) { 
            console.log(sessionData);
            
            contextValues.setCouponSession(sessionData[2])
            contextValues.setUserToken(localStorage.getItem("USER_TOKEN"))
            if (localStorage.getItem("USER_TOKEN")) {
                getUserAddress();
                cartSessionDataFetch();
                if (sessionData[2] && sessionData[2].promo_id) {
                     
                }
            } else {
                const guestCheckOut = localStorage.getItem("GUEST_CHECKOUT");
                const guestCheckOutData = guestCheckOut ? JSON.parse(guestCheckOut) : {};
                if (sessionData[1] && sessionData[1].length > 0 && guestCheckOutData.user_address && guestCheckOutData.user_address.ua_mobile != '') {
                    contextValues.setGuestCheckoutData(guestCheckOutData)
                    contextValues.setAddressSession(guestCheckOutData.user_address)
                    setShippingCharge(guestCheckOutData.shipping_amount)
                    contextValues.setCartCount(sessionData[1].length)
                    contextValues.setCartSessionData(sessionData[1])
                    contextValues.setCartSummary(sessionData[3])
                    if (sessionData[2] && sessionData[2].promo_id) {
                         
                    }
                } else {
                    Navigate("/");
                }
            }
            setTimeout(() => {
                setSpinnerLoading(false);
            }, 500);
        }
        didMountRef.current = false;
    }, [contextValues]);

    const getUserAddress = () => {
        ApiService.fetchData("get-user-address").then((res) => {
            if (res.status === "success") {
                if (res.resAddressDefault && res.resAddressDefault.ua_id > 0) {
                    contextValues.setUserSelectedAddress(res.resAddressDefault);
                }
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        });
    };

    const editAddress = () => {
        Navigate('/address')
    }

    const ContinueToPay = () => {
        if (parseFloat(contextValues.cartSummary.itemTotal) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) <= contextValues.settingData.admin_min_order) {
            toast.error("Minimum Order value $" + contextValues.settingData.admin_min_order);
            return false;
        }

        const dataString = {
            parsedCartSession: contextValues.cartSessionData,
        };
        setContinuePaySpinnerLoading(true)
        ApiService.postData("checkOrderSummary", dataString).then((res) => {
            
            if (res.status === "success") {
                if (!localStorage.getItem("USER_TOKEN")) {
                    localStorage.removeItem("CART_SESSION");
                    localStorage.setItem("CART_SESSION", JSON.stringify(res.updatedSessionData));
                }
                contextValues.setCartSessionData(res.updatedSessionData)
                contextValues.setCartSummary(res.cartSummary)
                const hasItemWithStatusTrue = res.updatedSessionData.some((item) => {
                    return (item.product_item_status === true);
                });

                if (!hasItemWithStatusTrue) {
                    const dataString = {
                        paymentMethod: paymentMethod,
                        shippingCharge: (contextValues.settingData && parseFloat(contextValues.settingData.admin_shipping_free) > parseFloat(res.cartSummary.total_amount)
                            ? parseFloat(contextValues.settingData.admin_notzone_amount)
                            : 0),
                        parsedAddressSession: contextValues.userToken ? contextValues.userSelectedAddress : contextValues.addressSession,
                        parsedCartSession: res.updatedSessionData,
                        parsedCouponSession: contextValues.couponSession ? contextValues.couponSession : { discount_amount: 0.0, promo_id: 0, promo_code: "", cart_amount: 0.0 },
                        parsedGiftCardSession: contextValues.GiftcardSession ? contextValues.GiftcardSession : { gt_amount: 0.0, gt_id: 0, gt_giftcard_code: "" },
                        textarea: textarea
                    }; 
                    if (paymentMethod === 'COD') {
                        ApiService.postData("makeCodOrder", dataString).then((res) => {
                            if (res.status === "success") {
                                localStorage.removeItem("GUEST_CHECKOUT")
                                localStorage.removeItem("CART_SESSION")
                                localStorage.removeItem("ADDRESS_SESSION")
                                localStorage.removeItem("COUPON_SESSION")
                                localStorage.removeItem("GIFTCARD_SESSION")
                                setContinuePaySpinnerLoading(false)
                                Navigate('/thankyou/' + res.order_number)
                            } else {
                                toast.error(res.message)
                                setContinuePaySpinnerLoading(false)
                            }
                        });
                    } else {
                        // setContinuePaySpinnerLoading(false);
                        // toast.error('Under Maintenance')
                        // return false;
                        ApiService.postData("createTempOrder", dataString).then((res) => {
                            if (res.status === "success") {
                                handlePayment(res.temp_trans_order_id, res.temp_razorpay_order_id)
                                setTimeout(() => {
                                    setContinuePaySpinnerLoading(false);
                                }, 4000);
                            } else {
                                toast.error(res.message)
                                setContinuePaySpinnerLoading(false);
                            }
                        });
                    }
                } else {
                    setContinuePaySpinnerLoading(false);
                }
            } else {
                setContinuePaySpinnerLoading(false);
            }
        });
    }

    const handlePayment = async (temp_trans_order_id,temp_razorpay_order_id) => {
        const options = {
            key: "rzp_live_L5ABuNyEjss7o7",
            amount: 0,
            currency: "INR",
            name: "PADMAKSHYA",
            description: "PADMAKSHYA",
            image: contextValues.settingImageBaseUrl + contextValues.settingData.logo,
            order_id: temp_razorpay_order_id,
            handler: function (response) {
                RazorPayProcess(response, temp_trans_order_id, paymentMethod);
            },
            prefill: {
                name: contextValues.userSelectedAddress.ua_fname + ' ' + contextValues.userSelectedAddress.ua_lname,
                email: contextValues.userSelectedAddress.ua_email,
                contact: contextValues.userSelectedAddress.ua_mobile,
            },
            notes: {
                address: "PADMAKSHYA",
            },
            theme: {
                color: "#ce8992",
            },
            method: {
                netbanking: paymentMethod == 'Net Banking' ? true : false,
                card: paymentMethod == 'Debit/Credit Cards' ? true : false,
                upi: paymentMethod == 'UPI' ? true : false,
                wallet: paymentMethod == 'Wallet' ? true : false,
            },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });

        rzp1.open();
    };

    const RazorPayProcess = (response, orderId) => {
        const paymentData = {
            paymentId: response.razorpay_payment_id,
            orderId: orderId,
            cartSummary: contextValues.cartSummary,
            paymentMethod: paymentMethod,
            shippingCharge: (contextValues.settingData && parseFloat(contextValues.settingData.admin_shipping_free) > parseFloat(contextValues.cartSummary.total_amount)
                            ? parseFloat(contextValues.settingData.admin_notzone_amount)
                            : 0),
            parsedAddressSession: contextValues.userToken ? contextValues.userSelectedAddress : contextValues.addressSession,
            parsedCartSession: contextValues.cartSessionData,
            parsedCouponSession: contextValues.couponSession ? contextValues.couponSession : { discount_amount: 0.0, promo_id: 0, promo_code: "", cart_amount: 0.0 },
            parsedGiftCardSession: contextValues.GiftcardSession ? contextValues.GiftcardSession : { gt_amount: 0.0, gt_id: 0, gt_giftcard_code: "" },

        };
        // console.log(paymentData);
        // return false;
        
        setContinuePaySpinnerLoading(true)
        ApiService.postData("paymentProcessing", paymentData).then((result) => {
            if (result.status === "success") {
                localStorage.removeItem("GUEST_CHECKOUT")
                localStorage.removeItem("CART_SESSION")
                localStorage.removeItem("ADDRESS_SESSION")
                localStorage.removeItem("COUPON_SESSION")
                localStorage.removeItem("GIFTCARD_SESSION")
                setContinuePaySpinnerLoading(false);
                Navigate('/thankyou/' + result.order_number)
            } else {
                setContinuePaySpinnerLoading(false);
            }
        });
    };

    const cartSessionDataFetch = () => {
        const dataString = {
            product_id: '',
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                if (res.data.resCartData && res.data.resCartData.length > 0) {
                    contextValues.setCartSessionData(res.data.resCartData)
                    contextValues.setCartCount(res.data.resCartData.length)
                    contextValues.setCartSummary(res.data.cartSummary)
                } else {
                    Navigate("/");
                }
            }
        });
    }
    const handleChangeTextarea = (event) => {
        setTextarea(event.target.value);
    };

    const selectPaymentMethod = (type) => {
        setPaymentMethod(type)
    }
    return (<>
    <BrowserView>
    <div className="checkout-wapper">
            <div className="left">
                <div className="checkoutLogo">
                    <a href="/">
                        <img src={contextValues.settingData.logo ? contextValues.settingImageBaseUrl + contextValues.settingData.logo : '/img/logo.png'}></img>
                    </a>
                </div>
                <ul className="checkoutStep">
                    <li>
                        <a href="/address">Address</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">Payment</a>
                    </li>
                </ul>
                <div className="row g-3">
                    {contextValues.cartSessionData.length > 0 && (
                        <>
                            {/* Address Section */}
                            {contextValues.userToken ?
                                <div className="mb-20">
                                    <div className="checkoutsectionTitle mb-20">
                                        <h5 className="mb-0 tx-16 fw400">Shipping Details</h5>
                                        <a href="javascript:void(0);" className="tx-primary" onClick={(e) => editAddress()}>Change</a>
                                    </div>
                                    <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                        <h6 className="mb-1 tx-14 fw400 tx-uppercase">{contextValues.userSelectedAddress.ua_fname} {contextValues.userSelectedAddress.ua_lname}</h6>
                                        <p className="mb-1 tx-14">{contextValues.userSelectedAddress.ua_apartment}, {contextValues.userSelectedAddress.ua_complete_address}, {contextValues.userSelectedAddress.ua_city_name}</p>
                                        <p className="mb-1 tx-14">{contextValues.userSelectedAddress.ua_state_name}, {contextValues.userSelectedAddress.ua_country_name}, {contextValues.userSelectedAddress.ua_pincode}</p>
                                        <p className="tx-gray mb-0 tx-14">Mobile No: {contextValues.userSelectedAddress.ua_mobile}</p>
                                    </div>
                                </div>
                                :
                                <div className="mb-20">
                                    <div className="checkoutsectionTitle mb-20">
                                        <h5 className="mb-0 tx-16 fw400">Shipping Details</h5>
                                        <a href="javascript:void(0);" className="tx-primary" onClick={(e) => editAddress()}>Change</a>
                                    </div>
                                    <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                        <h6 className="mb-1 tx-14 fw400 tx-uppercase">{contextValues.addressSession.ua_fname} {contextValues.addressSession.ua_lname}</h6>
                                        <p className="mb-1 tx-14">{contextValues.addressSession.ua_apartment}, {contextValues.addressSession.ua_complete_address}, {contextValues.addressSession.ua_city_name}</p>
                                        <p className="mb-1 tx-14">{contextValues.addressSession.ua_state_name}, {contextValues.addressSession.ua_country_name}, {contextValues.addressSession.ua_pincode}</p>
                                        <p className="mb-0 tx-14">Mobile No: {contextValues.addressSession.ua_mobile}</p>
                                    </div>
                                </div>
                            }
                            <div className="mb-20">
                                <div className="checkoutsectionTitle mb-10">
                                    <h5 className="mb-0 tx-16 fw400">Order Note</h5>
                                </div>
                                <div className="form-group">
                                    <textarea name="textarea" placeholder="How can we help you?" value={textarea} onChange={handleChangeTextarea}></textarea>
                                </div>
                            </div>
                            {/* Payment Section */}
                            <div className="mb-15">
                                <div className="cartSectionTitle mb-10">
                                    <h5 className="mb-0 tx-16 fw400">Payment Options</h5>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'COD' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('COD')}>
                                    <div className='ckboxbo-inner' >
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/deliverycod.png" style={{ width: '16%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Cash on delivery</p>
                                                <p className='tx-12 tx-gray mb-0'>Pay with cash</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'UPI' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('UPI')}>
                                    <div className='ckboxbo-inner' >
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/phonepe.png" style={{ width: '13%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Pay via UPI</p>
                                                <p className='tx-12 tx-gray mb-0'>Use any registered UPI ID</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'Debit/Credit Cards' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Debit/Credit Cards')}>
                                    <div className='ckboxbo-inner' >
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/creditcard.png" style={{ width: '11%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Debit/Credit cards</p>
                                                <p className='tx-12 tx-gray mb-0'>Visa, Mastercard, RuPay & more</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'Wallet' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Wallet')}>
                                    <div className='ckboxbo-inner'>
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/paytm.png" style={{ width: '11%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Wallets</p>
                                                <p className='tx-12 tx-gray mb-0'>Paypal, Airtel, PayZapp & more</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'Net Banking' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Net Banking')}>
                                    <div className='ckboxbo-inner' >
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/netbanking.png" style={{ width: '13%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Netbanking</p>
                                                <p className='tx-12 tx-gray mb-0'>Select from a list of banks</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-15">
                                <button className="btn btn-primary btn-full mb-20 mt-20 " type="button" onClick={(e) => ContinueToPay()}>{continuePaySpinnerLoading ? <img src="/img/loder01.gif" width="70px" height="13px" /> : 'Pay Now'}</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <OrderSummary />
        </div>
    
    </BrowserView>
    <MobileView>
    <div className="checkout-wapper">
            <div className="left">
                <div className="checkoutLogo">
                    <a href="/">
                        <img src={contextValues.settingData.logo ? contextValues.settingImageBaseUrl + contextValues.settingData.logo : '/img/logo.png'}></img>
                    </a>
                </div>
                <ul className="checkoutStep">
                    <li>
                        <a href="/address">Address</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">Payment</a>
                    </li>
                </ul>
                <div className="row g-3">
                    {contextValues.cartSessionData.length > 0 && (
                        <>
                            {/* Address Section */}
                            {contextValues.userToken ?
                                <div className="mb-20">
                                    <div className="checkoutsectionTitle mb-20">
                                        <h5 className="mb-0 tx-16 fw400">Shipping Details</h5>
                                        <a href="javascript:void(0);" className="tx-primary" onClick={(e) => editAddress()}>Change</a>
                                    </div>
                                    <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                        <h6 className="mb-1 tx-14 fw400 tx-uppercase">{contextValues.userSelectedAddress.ua_fname} {contextValues.userSelectedAddress.ua_lname}</h6>
                                        <p className="mb-1 tx-14">{contextValues.userSelectedAddress.ua_apartment}, {contextValues.userSelectedAddress.ua_complete_address}, {contextValues.userSelectedAddress.ua_city_name}</p>
                                        <p className="mb-1 tx-14">{contextValues.userSelectedAddress.ua_state_name}, {contextValues.userSelectedAddress.ua_country_name}, {contextValues.userSelectedAddress.ua_pincode}</p>
                                        <p className="tx-gray mb-0 tx-14">Mobile No: {contextValues.userSelectedAddress.ua_mobile}</p>
                                    </div>
                                </div>
                                :
                                <div className="mb-20">
                                    <div className="checkoutsectionTitle mb-20">
                                        <h5 className="mb-0 tx-16 fw400">Shipping Details</h5>
                                        <a href="javascript:void(0);" className="tx-primary" onClick={(e) => editAddress()}>Change</a>
                                    </div>
                                    <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                        <h6 className="mb-1 tx-14 fw400 tx-uppercase">{contextValues.addressSession.ua_fname} {contextValues.addressSession.ua_lname}</h6>
                                        <p className="mb-1 tx-14">{contextValues.addressSession.ua_apartment}, {contextValues.addressSession.ua_complete_address}, {contextValues.addressSession.ua_city_name}</p>
                                        <p className="mb-1 tx-14">{contextValues.addressSession.ua_state_name}, {contextValues.addressSession.ua_country_name}, {contextValues.addressSession.ua_pincode}</p>
                                        <p className="mb-0 tx-14">Mobile No: {contextValues.addressSession.ua_mobile}</p>
                                    </div>
                                </div>
                            }
                            <div className="mb-20">
                                <div className="checkoutsectionTitle mb-10">
                                    <h5 className="mb-0 tx-16 fw400">Order Note</h5>
                                </div>
                                <div className="form-group">
                                    <textarea name="textarea" placeholder="How can we help you?" value={textarea} onChange={handleChangeTextarea}></textarea>
                                </div>
                            </div>
                            {/* Payment Section */}
                            <div className="mb-15">
                                <div className="cartSectionTitle mb-10">
                                    <h5 className="mb-0 tx-16 fw400">Payment Options</h5>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'COD' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('COD')}>
                                    <div className='ckboxbo-inner' >
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/deliverycod.png" style={{ width: '16%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Cash on delivery</p>
                                                <p className='tx-12 tx-gray mb-0'>Pay with cash</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'UPI' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('UPI')}>
                                    <div className='ckboxbo-inner' >
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/phonepe.png" style={{ width: '13%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Pay via UPI</p>
                                                <p className='tx-12 tx-gray mb-0'>Use any registered UPI ID</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'Debit/Credit Cards' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Debit/Credit Cards')}>
                                    <div className='ckboxbo-inner' >
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/creditcard.png" style={{ width: '11%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Debit/Credit cards</p>
                                                <p className='tx-12 tx-gray mb-0'>Visa, Mastercard, RuPay & more</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'Wallet' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Wallet')}>
                                    <div className='ckboxbo-inner'>
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/paytm.png" style={{ width: '11%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Wallets</p>
                                                <p className='tx-12 tx-gray mb-0'>Paypal, Airtel, PayZapp & more</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                                <div className={`ckboxbo mb-15 ${paymentMethod === 'Net Banking' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Net Banking')}>
                                    <div className='ckboxbo-inner' >
                                        <div className='ckboxbo-icon'>
                                            <img className="mr-10" src="/img/netbanking.png" style={{ width: '13%' }} />
                                            <div>
                                                <p className='tx-14 mb-0'>Netbanking</p>
                                                <p className='tx-12 tx-gray mb-0'>Select from a list of banks</p>
                                            </div>
                                        </div>
                                        <div className='ckboxbo-itemscount tx-14'><spna className="paymentselect"></spna></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-15">
                                <button className="btn btn-primary btn-full btnw-200" type="button" onClick={(e) => ContinueToPay()}>{continuePaySpinnerLoading ? <img src="/img/loder01.gif" width="70px" height="13px" /> : 'Pay Now'}</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <OrderSummary />
        </div>
    </MobileView>
       

    </>)
}

export default Checkout